@use "../../mixins";
@mixin large-screen {
  @media screen and (min-width: 993px) {
    @content;
  }
}
.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;

  .timeline-heading {
    padding-left: 60px;
  }
  @include large-screen {
    .timeline-heading {
      padding-left: 0;
    }
    &:before {
      top: 0;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 1px;
      background-color: #dedede;
      left: 50%;
      margin-left: -1.5px;
    }
  }

  > li {
    margin-bottom: 50px;
    position: relative;
    &:last-child {
      margin-bottom: 20px;
      &:after {
        display: none;
      }
    }
    &:before {
      content: " ";
      display: table;
      content: " ";
      display: table;
    }
    &:after {
      content: " ";
      display: table;
      clear: both;
      content: " ";
      display: table;
      clear: both;
    }
    > .timeline-panel {
      border: 1px solid #dedede;
      border-radius: 2px;
      padding: 20px;
      position: relative;

      @include large-screen {
        width: 50%;
        float: left;

        &:before {
          position: absolute;
          top: 26px;
          right: -15px;
          display: inline-block;
          border-top: 15px solid transparent;
          border-left: 15px solid #ccc;
          border-right: 0 solid #ccc;
          border-bottom: 15px solid transparent;
          content: " ";
        }
        &:after {
          position: absolute;
          top: 27px;
          right: -14px;
          display: inline-block;
          border-top: 14px solid transparent;
          border-left: 14px solid #fff;
          border-right: 0 solid #fff;
          border-bottom: 14px solid transparent;
          content: " ";
        }
      }
    }
    &:not(.timeline-inverted) {
      @include large-screen {
        + {
          li.timeline-inverted {
            margin-top: -60px;
          }
        }
        padding-right: 90px;
      }
    }
    > .timeline-badge {
      color: #fff;
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 1.4em;
      text-align: center;
      position: absolute;
      top: 16px;
      left: 0;
      margin-left: 15px;
      background-color: #ffffff;
      border: 1px solid #dedede;
      z-index: 100;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
      @include large-screen {
        left: 50%;
        margin-left: -25px;
      }
      h4 {
        line-height: 50px;
        color: #323232;
      }
    }
  }
  .timeline-body {
    p {
      line-height: 24px;
    }
  }
  > li.timeline-inverted {
    @include large-screen {
      + {
        li {
          &:not(.timeline-inverted) {
            margin-top: -60px;
          }
        }
      }
      padding-left: 90px;

      > .timeline-panel {
        float: right;
        &:before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -15px;
          right: auto;
        }
        &:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
        }
      }
    }
  }
  .timeline-heading {
    h5 {
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 20px;

      &:before {
        position: absolute;
        content: "";
        width: 20px;
        height: 2px;
        background: #db2d2e;
        left: 0px;
        bottom: 0px;
      }
    }
  }
}
