.icon {
  display: inline-block;
  width: 40px;
  height: 35px;
  margin-right: 5px;
  svg {
    width: 100%;
    height: 100%;
    fill: white;
  }
}

.separator {
  &:after {
    position: absolute;
    bottom: 0;
    float: left;
    height: 50px;
    display: block;
    margin: auto;
    content: "";
    width: 1px;
    background: #ffffff;
    left: 5px;
    transition: height 0.8s ease, background-color 0.8s ease;
    -webkit-transition: height 0.8s ease, background-color 0.8s ease;
    -o-transition: height 0.8s ease, background-color 0.8s ease;
    -ms-transition: height 0.8s ease, background-color 0.8s ease;
    -moz-transition: height 0.8s ease, background-color 0.8s ease;
  }
  &:before {
    position: absolute;
    bottom: 0;
    float: left;
    content: "";
    display: block;
    margin: auto;
    width: 1px;
    height: 30px;
    background: #ffffff;
    transition: height 0.8s ease, background-color 0.8s ease;
    -webkit-transition: height 0.8s ease, background-color 0.8s ease;
    -o-transition: height 0.8s ease, background-color 0.8s ease;
    -ms-transition: height 0.8s ease, background-color 0.8s ease;
    -moz-transition: height 0.8s ease, background-color 0.8s ease;
    margin-right: 10px;
  }
}

.item {
  &:hover {
    .separator {
      &:before {
        height: 50px;
      }
      &:after {
        height: 30px;
      }
    }
  }
}

.info {
  margin-left: 30px;
  margin-bottom: 20px;
  display: block;
  h6 {
    font-size: 12px;
  }

  i {
    &:before {
      font-size: 30px;
      color: #ffffff;
      display: inline-block;
      margin-right: 10px;
    }
  }
  b {
    font-size: 25px;
    font-weight: 600;
    margin: 10px 0px;
  }
  @media screen and (min-width: 993px) {
    margin-bottom: 0px;
    h6 {
      font-size: 16px;
    }
    b {
      font-size: 40px;
    }
  }
}
.counterBlock {
  margin-left: 16px;
  position: relative;
}

.bg {
  background: url(../../images/bg/counters.jpg);
  background-clip: initial;
  background-color: rgba(0, 0, 0, 0);
  background-origin: initial;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  background-attachment: fixed;
  padding: 40px 0;
  @media screen and (min-width: 993px) {
    margin-bottom: 80px;
  }

  &:before {
    background: rgba(0, 0, 0, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}
