footer {
  background: url(../../images/bg/footer.jpg);
  background-clip: initial;
  background-color: rgba(0, 0, 0, 0);
  background-origin: initial;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: auto 100%;

  position: relative;
  z-index: 0;
  background-attachment: fixed;
  @media screen and (min-width: 993px) {
    background-size: 100% auto;
  }
  h6 {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 26px;
    &:before {
      position: absolute;
      content: "";
      width: 20px;
      height: 2px;
      background: #db2d2e;
      left: 0;
      bottom: 0px;
    }
  }
  .news-letter {
    .form-control {
      border-color: rgba(255, 255, 255, 0.11);
      &:focus {
        border-color: #db2d2e;
      }
    }
  }
  hr {
    margin-top: 60px;
    border-color: rgba(255, 255, 255, 0.11);
  }
  .copyright {
    margin: 40px 0 0px;
    padding-bottom: 30px;

    a {
      color: inherit;
      padding: 0px 3px;
      &:hover {
        color: #db2d2e;
      }
    }
    ul {
      li {
        padding: 0px;
      }
    }
  }
  &:before {
    background: rgba(0, 0, 0, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}
