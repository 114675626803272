.faGearbox {
  background-image: url(../../images/icon-gearbox.svg);
  width: 1em;
  height: 1em;
  display: inline-block;
  background-size: contain;
  vertical-align: text-bottom;
  background-repeat: no-repeat;
  background-position-y: -1px;
}

.year {
  font-weight: normal;
}

.carGrid {
  margin-top: 15px;
  border: 1px solid #e3e3e3;
  padding: 20px;

  img {
    width: 100%;
    border: 1px solid rgb(211, 211, 211);
  }
}

.carTitle {
  position: relative;
  font-weight: 18px;
  font-weight: 600;

  text-underline-offset: 2px;
  margin-top: 10px;
  a {
    color: #363636;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (min-width: 993px) {
    font-size: 20px;
    margin-top: 0;
  }
}
.price {
  span {
    color: #db2d2e;
    font-weight: 700;
    font-size: 20px;
  }
}
.carList {
  margin: 10px 0;
  li {
    border: 1px solid #e3e3e3;
    padding: 0px 5px;
    font-size: 11px;
    display: inline-block;
    width: 50%;
  }
  li:nth-child(2n) {
    border-left-width: 0;
  }
  li:nth-child(3),
  li:nth-child(4) {
    border-top-width: 0;
  }

  @media screen and (min-width: 993px) {
    li {
      width: auto;
      padding: 1px 10px;
      font-size: 12px;
    }
    li + li {
      border-left: 0;
    }
    li:nth-child(3),
    li:nth-child(4) {
      border-top-width: 1px;
    }
  }
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
  @media screen and (min-width: 993px) {
    -webkit-line-clamp: 5;
    font-size: 14px;
  }
}
