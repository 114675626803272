.gray-form {
  height: 600px;
  overflow: hidden;
  iframe {
    width: 100%;
    overflow: hidden;
    border: 0;
    height: 963px;
    margin-top: -150px;
    @media screen and (min-width: 933px) {
      margin-top: -160px;
    }
  }
}
.gray-form textarea {
  min-height: 250px;
}
#formmessage {
  display: none;
}
.opening-hours {
  background: #f6f6f6;
  padding: 25px;
  h6 {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 15px;
    &:before {
      position: absolute;
      content: "";
      width: 20px;
      height: 2px;
      background: #db2d2e;
      left: 0;
      bottom: 0px;
    }
  }
  strong {
    width: 100px;
    display: inline-block;
    color: #363636;
  }
}
