.search {
  [type="search"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='grey' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'%3E%3C/path%3E%3C/svg%3E");
    background-size: 14px;
    padding-right: 30px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 12px;
  }
  input {
    width: 100%;
    &::placeholder {
      color: #aaa;
    }
  }
  select {
    width: 100%;
  }
  .range-search {
    display: flex;
    input,
    select {
      width: 50%;
    }
    span {
      line-height: 14px;
      padding: 12px 0;
      width: 20px;
      text-align: center;
      font-size: 24px;
    }
  }
  .search-block {
    padding: 15px 15px 15px;
    background: #ffffff;
    border: 1px solid #e3e3e3;

    position: relative;
    z-index: 1;
    @media screen and (min-width: 993px) {
      margin-top: -70px;
    }
    .selectpicker {
      margin-bottom: 15px;
      border-radius: 0px;
      border-color: #e3e3e3;
    }
    label {
      color: white;
    }

    .select {
      height: 50px;
      margin-bottom: 10px;
    }
    .select-styled {
      padding: 13px 15px;
      color: #999999;
      &:after {
        top: 22px;
        opacity: 0.5;
      }
      &:active {
        &:after {
          top: 14px;
        }
      }
    }
    .select-styled.active {
      &:after {
        top: 14px;
      }
    }
    .select-options {
      li {
        color: #999999;
      }
    }
    .selected-box {
      .select-options {
        max-height: 170px;
        overflow: auto;
      }
    }
  }
}
.search-block.red-bg {
  background: #db2d2e;
  border: 1px solid #db2d2e;
  span {
    color: #ffffff;
  }

  .ui-widget-header {
    background: #101010;
  }
}
