.objects-car {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.welcome-block {
  p {
    margin-top: 20px;
    display: block;
    strong {
      font-style: italic;
    }
  }
  .help-call {
    margin-top: 80px;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 5px;
    }
    span {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 18px;
      margin-bottom: 15px;
      display: block;
    }
  }
  svg.icon {
    width: 40px;
    height: 40px;
    margin-top: 20px;
  }

  svg.icon.car-exchange-2 {
    width: 50px;
    height: 50px;
    margin-top: 15px;
  }
}
.welcome-block.objects-car {
  overflow: hidden;
  position: relative;
}

.welcome-block.why-choose {
  .help-call {
    margin-top: 0px;
  }
}
.welcome-block .objects-left {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0px;
  z-index: -1;
  .objects-1 {
    position: absolute;
    left: 0px;
    bottom: 0;
  }
}
.welcome-block .objects-right {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0px;
  z-index: -1;
  .objects-2 {
    position: absolute;
    right: 0px;
    bottom: 0;
  }
}
.welcome-block .feature-box {
  i {
    text-align: center;
    width: 80px;
    height: 80px;
    font-size: 30px;
    line-height: 80px;
    background: #f6f6f6;
    display: inline-block;
    border-radius: 50%;
    color: #969696;
    margin-bottom: 20px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
  }
  h6 {
    position: relative;
    padding-bottom: 10px;
    &:before {
      position: absolute;
      content: "";
      width: 20px;
      height: 2px;
      background: #db2d2e;
      left: 50%;
      margin-left: -10px;
      bottom: 0px;
    }
  }
  p {
    padding: 0px;
  }
  &:hover {
    i {
      background: #db2d2e;
      color: #ffffff;
    }
  }
}
