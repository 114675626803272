.carouselCaption {
  position: absolute;
  text-align: left;
  left: calc(50% + 100px);

  top: 200px;
  h3 {
    text-shadow: 0px 0px 2px black;
    font-size: 30px;
    color: white;
    margin-top: 10px;
  }

  div {
    color: white;
    font-size: 16px;
    margin-bottom: 10px;
  }

  left: calc(50% + 148px);
  width: 406px;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px 20px;
  a {
    text-decoration: none !important;
  }
}

.carouselItem {
  text-align: center;
  img {
    margin-left: -100%;
    left: 50%;
    position: relative;
  }
}
:global {
  .carousel {
    display: none;
    @media screen and (min-width: 933px) {
      display: block;
    }
  }

  .BrainhubCarousel__dots {
    bottom: 90px;
    li {
      background-color: #fff;
      &.active {
        background-color: red;
      }
    }
  }
  .slideInner___2mfX9 {
    overflow: hidden;
  }
  .BrainhubCarousel__arrows {
    background-color: #db2d2e !important;
  }
  .slideHorizontal___1NzNV {
    padding-bottom: 480px !important;
  }
}

$size: 60px;
$offset: $size/2;
$arrow: $size/10;

.rightButton {
  display: block;
  width: $size;
  height: $size;
  position: absolute;
  bottom: calc(50% - #{$size/2});
  right: $offset;
  background: #db2d2e;
  border-radius: $size;
  transition: bottom 0.2s ease;
  color: white;
  font-size: 10px;
  text-align: center;
  line-height: 80px;
  overflow: hidden;
  opacity: 0.9;
  z-index: 998;
  transform: rotate(90deg);
  &:before,
  &:after {
    display: block;
    position: absolute;
    content: "";
    width: $arrow * 3;
    height: $arrow;
    background: white;
    top: calc(50% - #{$arrow * 01});
    left: 50%;
    margin-left: -$arrow/1.3;
    transform: rotate(45deg);
    border-radius: $arrow/3;
  }
  &:after {
    left: calc(50% - #{$arrow * 1.5});
    transform: rotate(-45deg);
  }
}

.leftButton {
  @extend .rightButton;
  right: auto;
  left: $offset;
  transform: rotate(-90deg);
}
