.topbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 0px;
  background: black;
}
.topbar li,
.topbar li a {
  color: #ffffff;
}
.topbar li a:hover {
  color: #db2d2e;
}
.topbar-left li i {
  padding-right: 5px;
}
.topbar-right li i {
  padding-right: 5px;
}
.topbar-right .list-inline li:last-child,
.topbar-right li:last-child i {
  padding-right: 0px;
}
