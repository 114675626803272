.mega-menu * {
  outline: none;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box !important;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
}

.mega-menu img {
  /*------------------------------- IMAGE */
  margin: 0;
  padding: 0;
  display: block;
  max-width: 100% !important;
  float: left;
}

.mega-menu input {
  /*------------------------------ DEFAULT INPUT */
  border: none;
}

.menu-logo {
  /*----------------------------------- MENU LOGO */
  margin: 0;
  padding: 0;
  display: block;
  float: left;
  position: relative;
}

@media screen and (min-width: 993px) {
  .mega-menu .menu-logo.menu-logo-align-right {
    /*---------------------------------------------- MENU LOGO ALIGN RIGHT */
    float: right;
  }
}

@media screen and (max-width: 992px) {
  .menu-logo {
    width: 100%;
  }

  .menu-logo > li {
    width: 100%;
    line-height: normal;
  }
}

.menu-logo > li > a {
  font-size: 36px;
  color: #db2d2e;
  font-weight: bold;
  list-style: 36px;
  text-decoration: none !important;
}

@media screen and (max-width: 992px) {
}

.mega-menu .menu-links {
  margin: 0;
  padding: 0;
  display: block;
  float: right;
}

@media screen and (max-width: 992px) {
  .mega-menu .menu-links {
    width: 100%;
    background: #fff;
  }
}

@media screen and (min-width: 993px) {
  .mega-menu .menu-links {
    display: block !important;
    max-height: 100% !important;
    overflow: visible !important;
  }

  .mega-menu .menu-links.menu-links-align-right {
    /*------------------------------------------ MENU LINKS ALIGN RIGHT */
    float: right;
  }
}

.mega-menu .menu-links > li {
  margin: 0;
  padding: 0;
  display: block;
  float: left;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-size: 1em;
}

@media screen and (max-width: 992px) {
  .mega-menu .menu-links > li {
    width: 100%;
    position: relative;
    cursor: pointer;
    line-height: normal;
    border-bottom: 1px solid #f3f3f3;
    z-index: 50;
  }
}

.mega-menu .menu-links > li > a {
  padding: 0 13px;
  display: block;
  text-transform: capitalize;
}

@media screen and (min-width: 993px) {
  #header.topbar-dark {
    z-index: 999;
    background: #ffffff;
    width: 100%;
    position: sticky;
    border-bottom: 1px solid #e3e3e3;
    background: #ffffff;

    padding: 0px;
    margin: 0px;

    top: 0;
  }
}

.mega-menu {
  .menu-logo {
    padding: 14px 0px;
    img {
      height: 36px;
    }
  }
  .menu-links {
    > li {
      > a {
        line-height: 66px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        color: #323232;
        &:hover {
          color: #db2d2e;
        }
      }
      a.active {
        color: #db2d2e;
      }
      &:last-child {
        > a {
          padding-right: 0px;
        }
      }
      &:first-child {
        > a {
          padding-left: 0;
        }
      }
    }

    a {
      color: #555555;
      &:hover {
        color: #db2d2e;
      }
    }
    a.active {
      color: #db2d2e;
    }
  }
  .drop-down-multilevel.right-menu {
    right: 0px;
  }
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .col-md-12 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
