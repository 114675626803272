@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #323232;
}
a {
  &:hover {
    color: #db2d2e;
  }
}
.uppercase {
  text-transform: uppercase;
}
h1 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #363636;
  margin-top: 0px;
  text-transform: uppercase;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  a {
    color: inherit;
  }
}
h2 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #363636;
  margin-top: 0px;
  text-transform: uppercase;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  a {
    color: inherit;
  }
}
h3 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #363636;
  margin-top: 0px;
  text-transform: uppercase;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  a {
    color: inherit;
  }
}
h4 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #363636;
  margin-top: 0px;
  text-transform: uppercase;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  a {
    color: inherit;
  }
}
h5 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #363636;
  margin-top: 0px;
  text-transform: uppercase;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  a {
    color: inherit;
  }
}
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #363636;
  margin-top: 0px;
  text-transform: uppercase;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  a {
    color: inherit;
  }
}
ul {
  margin: 0px;
  padding: 0px;
  padding: 0px;
  margin: 0px;
  li {
    list-style: square inside;
    padding: 0px;
    margin: 0px;
    line-height: 30px;
  }
}
hr {
  margin: 0;
  padding: 0px;
  border-bottom: 1px solid #232323;
  border-top: 0px;
}
hr.gray {
  margin: 0;
  padding: 0px;
  border-bottom: 1px solid #dedede;
  border-top: 0px;
}
label {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
  &:after {
    font-family: FontAwesome;
    font-size: 17px;
    position: absolute;
    top: -3px;
    left: 0;
    padding-left: 2px;
  }
}
.label {
  color: #fff !important;
  font-size: 9px !important;
}
* {
  &::-moz-selection {
    background: #db2d2e;
    color: #fff;
    text-shadow: none;
  }
}
::-moz-selection {
  background: #db2d2e;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #db2d2e;
  color: #fff;
  text-shadow: none;
}
mark {
  background: #db2d2e;
  color: #ffffff;
}
ol {
  padding: 0px;
  margin: 0px;
  li {
    list-style: decimal inside;
    padding: 0px;
    margin: 0px;
    line-height: 30px;
  }
}
ol.alpha {
  li {
    list-style: decimal-leading-zero inside;
    padding: 0px;
    margin: 0px;
  }
}
ol.roman {
  padding-left: 15px;
  li {
    list-style: lower-roman;
    padding: 0px;
    margin: 0px;
  }
}
ul.disc {
  li {
    list-style: disc inside;
    padding: 0px;
    margin: 0px;
    line-height: 30px;
  }
}
ul.list-style-1 {
  li {
    list-style: none;
  }
}

blockquote {
  display: block;
  background: #fff;
  position: relative;
  font-size: 14px;
  padding-left: 60px;
  border-left: 5px solid #f5f5f5;
  font-style: italic;
  padding-right: 0px;
  &::before {
    content: "\201C";
    font-family: Georgia, serif;
    font-size: 60px;
    font-weight: bold;
    color: #e3e3e3;
    position: absolute;
    left: 10px;
    top: 25px;
  }
  &::after {
    content: "";
  }
  cite {
    font-size: 14px;
    margin-top: 15px;
    display: block;
    font-weight: 600;
    color: #db2d2e;
  }
}

.list-inline {
  > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }
}
.list-style-none {
  li {
    list-style: none;
  }
}
.page-section-ptb {
  padding: 80px 0;
}
.text-left {
  .section-title {
    text-align: left;
    .separator {
      &:before {
        left: 0;
        margin-left: 0;
      }
      &:after {
        left: 0;
        margin-left: 0;
      }
    }
  }
}
.text-white {
  color: #ffffff;
}
.white-bg {
  background: #ffffff;
}
.text-red {
  color: #db2d2e;
}
.section-title {
  margin-bottom: 30px;
  text-align: center;
}
.section-title h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 600;
  @media (min-width: 993px) {
    h2 {
      font-size: 35px;
    }
  }
}
.section-title span {
  margin-bottom: 10px;
  display: block;
}
.section-title .separator {
  position: relative;
  margin-top: 10px;
  display: inline-block;
}
.section-title .separator:before {
  content: "";
  display: block;
  margin: auto;
  left: 50%;
  height: 1px;
  width: 50px;
  bottom: -15px;
  background: #db2d2e;
  transition: width 0.8s ease, background-color 0.8s ease;
  -webkit-transition: width 0.8s ease, background-color 0.8s ease;
  -o-transition: width 0.8s ease, background-color 0.8s ease;
  -ms-transition: width 0.8s ease, background-color 0.8s ease;
  -moz-transition: width 0.8s ease, background-color 0.8s ease;
  margin-bottom: 6px;
}
.section-title .separator:after {
  width: 100px;
  display: block;
  margin: auto;
  content: "";
  height: 1px;
  background: #db2d2e;
  bottom: 0px;
  left: 50%;
  transition: width 0.8s ease, background-color 0.8s ease;
  -webkit-transition: width 0.8s ease, background-color 0.8s ease;
  -o-transition: width 0.8s ease, background-color 0.8s ease;
  -ms-transition: width 0.8s ease, background-color 0.8s ease;
  -moz-transition: width 0.8s ease, background-color 0.8s ease;
}

.button,
button {
  text-align: center;
  background: #db2d2e;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  padding: 8px 20px;
  border: 0;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
  &:focus {
    color: #ffffff;
  }
  &:active {
    color: #ffffff;
  }
  &.border {
    background: transparent;
    border: 1px solid #e3e3e3;
    color: #323232;
    &:before {
      background: #db2d2e;
    }
    &:hover {
      color: #ffffff;
      border-color: #db2d2e;
    }
    &:focus {
      color: #ffffff;
      border-color: #db2d2e;
      color: #ffffff;
      border-color: #db2d2e;
      color: #db2d2e;
      border-color: #ffffff;
    }
    &:active {
      color: #ffffff;
      border-color: #db2d2e;
      color: #ffffff;
      border-color: #db2d2e;
      color: #db2d2e;
      border-color: #ffffff;
    }
  }
}
a.button {
  text-underline-offset: 3px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

select {
  border: 1px solid #232323;
  color: #777;
  cursor: pointer;
  display: block;
  line-height: 14px;
  max-width: 100%;
  padding: 14px;
}
.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #e3e3e3;
  width: 100%;
  height: 40px;
}
.select-styled {
  text-align: left;
  font-size: 14px;
  color: #323232;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  padding: 8px 15px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  &:after {
    content: "";
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: #e3e3e3 transparent transparent transparent;
    position: absolute;
    top: 18px;
    right: 10px;
  }
  &:active {
    &:after {
      top: 10px;
      border-color: transparent transparent #e3e3e3 transparent;
    }
  }
  &.active {
    &:after {
      top: 10px;
      border-color: transparent transparent #e3e3e3 transparent;
    }
  }
}
.select-options {
  text-align: left;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 99;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-top: 0px;
  li {
    list-style: none;
    font-size: 14px;
    padding-left: 15px;
    color: #323232;
  }
}

.form-control {
  border-radius: 0;
  font-size: 14px;
  line-height: 20px;
  padding: 9px;
}

#backToTop {
  $size: 60px;
  $offset: $size/2;
  $arrow: $size/10;
  display: block;
  width: $size;
  height: $size;
  position: fixed;
  bottom: -$size;
  right: $offset;
  background: #db2d2e;
  border-radius: $size;
  transition: bottom 0.2s ease;
  color: white;
  font-size: 10px;
  text-align: center;
  line-height: 80px;
  overflow: hidden;
  opacity: 0.9;
  z-index: 1000;
  &:before,
  &:after {
    display: block;
    position: absolute;
    content: "";
    width: $arrow * 3;
    height: $arrow;
    background: white;
    top: calc(50% - #{$arrow * 1.5});
    left: 50%;
    margin-left: -$arrow/1.3;
    transform: rotate(45deg);
    border-radius: $arrow/3;
  }
  &:after {
    left: calc(50% - #{$arrow * 1.5});
    transform: rotate(-45deg);
  }
  &.visible {
    bottom: $offset;
  }
}

@media (max-width: 1272px) {
  .topbar li,
  .topbar li a {
    font-size: 13px;
  }
}

@media (max-width: 1025px) {
  .mega-menu .menu-links > li > a {
    padding: 0 10px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .container {
    width: 94%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .container {
    max-width: 96%;
  }
}

@media (max-width: 992px) {
  .page-section-ptb {
    padding: 60px 0;
  }

  .mega-menu .menu-logo > li > a {
    padding: 0px;
  }

  .mega-menu .menu-logo {
    padding: 20px 0;
  }

  .mega-menu .menu-logo img {
    height: 24px;
  }

  .mega-menu .menu-links {
    border: 1px solid #e3e3e3;
  }

  .mega-menu .menu-links > li {
    line-height: 44px;
  }

  .mega-menu .menu-links > li:last-child {
    border-bottom: 0px;
  }

  .welcome-block p {
    padding: 0px;
  }

  .feature-box {
    margin-bottom: 30px;
  }

  .why-choose .feature-box {
    margin-bottom: 30px;
  }

  .mega-menu {
    min-height: 62px;
  }

  #header.topbar-dark .mega-menu .menu-logo {
    padding: 20px 0px;
  }

  .search .search-block span {
    font-size: 12px;
  }

  .timeline > li > .timeline-badge {
    left: 25px;
  }

  .timeline > li > .timeline-panel {
    width: 100%;
  }

  .objects-left {
    display: none;
  }

  .objects-right {
    display: none;
  }

  #header.topbar-dark .mega-menu .menu-links > li:first-child > a {
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 32px;
  }

  .section-title {
    margin-bottom: 40px;
  }

  .section-title h2 {
    font-size: 30px;
  }
}
