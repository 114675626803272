.accordion-title {
  font-size: 14px;
  color: #000;
  font-weight: 700;
  background: transparent;
  margin-top: 20px;
  border: 1px solid #e3e3e3;
  display: block;
  position: relative;
  color: #363636;
  cursor: pointer;
  width: 100%;
  text-align: left;
  text-decoration: none;
  padding: 11px 60px;

  &:hover {
    text-decoration: none;
    background-color: transparent;
    color: #db2d2e;
    &:after {
      color: #db2d2e;
    }
  }
  &:active:not(.active),
  &:focus:not(.active) {
    color: inherit;
  }
  &:after {
    content: " ";
    width: 15px;
    height: 15px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    left: 20px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #626262;
    transform: rotate(90deg);
    -webkit-font-smoothing: antialiased;
    background: url(../../images/arrow-down.svg);
    background-size: contain;
  }

  .active & {
    color: #fff !important;
    background-color: #db2d2e;
    cursor: default;
    border-color: #db2d2e;
    border-top: 0px;
    &:after {
      color: #fff;
      transform: rotate(-90deg);
    }
  }
}

.accordion-content {
  display: none;
  padding: 25px;
  border: 1px solid #e3e3e3;

  .active & {
    display: block;
  }
}
.career {
  .career-info {
    margin-top: 50px;
    p {
      margin-bottom: 30px;
      a {
        color: #db2d2e;
      }
    }
  }
  .gray-form {
    margin-top: 30px;
  }
}
